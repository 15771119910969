export const theme = {
  radius: '4px',
  outline: '2px solid #7DB4F7',
  maxWidth: '1280px',
  color: {
    blue: '#7DB4F7',
    lightBlue: '#D8E9FD',
    darkBlue: '#092F5D',
    hoverBlue: '#21436d',
    lightPink: '#F5CCCC',
    hoverPink: '#f7d6d6',
    lightGrey: '#FBFBFB',
    darkGrey: '#424B4C',
  },
  fontSize: {
    headingBig: '8.6rem',
    headingPrimary: '7.2rem',
    headingPrimaryMobile: '4.5rem',
    headingSecondary: '5rem',
    headingSecondaryMobile: '3.5rem',
    headingTertiary: '2rem',
    blockquote: '4rem',
    blockquoteMobile: '2.5rem',
    body: '1.6rem',
    caption: '1.8rem',
    mobileNav: '2.2rem',
    note: '1.2rem',
  },
  fontFamily: {
    montserrat: '"Montserrat", sans-serif',
    cormorant: '"Cormorant", serif',
  },
  mq: {
    tablet: '@media (min-width: 768px)',
    desktop: '@media (min-width: 1024px)',
    bigDesktop: '@media (min-width: 1440px)',
    huge: '@media(min-width: 1920px)',
    hover: '@media (hover: hover)', 
  },
};
